import { sanitizeHTML } from "@/lib/utils";
import Link from "@/components/ui/Link";
import Button from "../ui/button";

interface ContentSplitTextMapData {
  heading: {
    text?: string;
    title?: string;
  };
  subheading: {
    text?: string;
    title?: string;
  };
  text: {
    text?: string;
  };
  button: {
    label?: string;
    href?: string;
  };
  map_url: string;
}

export const fields = [
  "heading.text",
  "heading.title",
  "subheading.text",
  "subheading.title",
  "text.text",
  "button.label",
  "button.href",
  "map_url",
] as const;

interface ContentSplitTextMapProps {
  data: ContentSplitTextMapData;
}

export default function ContentSplitTextMap({
  data,
}: ContentSplitTextMapProps) {
  return (
    <section
      id="content-split-text-map"
      className="bg-[rgba(177,163,164,0.15)] py-8 md:py-12"
    >
      <div className="max-w-[1080px] mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          <div className="space-y-6 font-aileron">
            <div>
              <h2
                className="text-4xl md:text-5xl font-freightBig font-medium mb-4"
                dangerouslySetInnerHTML={sanitizeHTML(
                  data?.subheading?.title || data?.subheading?.text || ""
                )}
              />
              <div
                className="text-base prose prose-p:my-4"
                dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")}
              />
            </div>
          </div>
          <div>
            <h2
              className="text-4xl md:text-5xl font-freightBig font-medium mb-4"
              dangerouslySetInnerHTML={sanitizeHTML(
                data?.heading?.title || data?.heading?.text || ""
              )}
            />
            <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden">
              <iframe
                src={data?.map_url || ""}
                className="w-full h-full border-0"
                loading={"lazy"}
                referrerPolicy="no-referrer-when-downgrade"
                title="Location Map"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          {data?.button && <Button asChild variant="outline">
            <Link href={data?.button?.href || ""}>
              {data?.button?.label || ""}
            </Link>
          </Button>}
        </div>
      </div>
    </section>
  );
}
