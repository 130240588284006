"use client";

import Video from "@/components/shared/VideoComp/Video";
import Button from "@/components/ui/button";
import Link from "@/components/ui/Link";
import { sanitizeHTML } from "@/lib/utils";

interface ContentSplitLeftVideoWhiteData {
  heading?: {
    title?: string;
  };
  text?: {
    text?: string;
  };
  video?: {
    embed_url?: string;
    embed_title?: string;
    description?: string;
  };
  button?: {
    label?: string;
    href?: string;
  };
}

export const fields = [
  "heading.title",
  "text.text",
  "video.embed_url",
  "video.embed_title",
  "video.description",
  "button.label",
  "button.href",
] as const;

interface ContentSplitLeftVideoWhiteProps {
  data?: ContentSplitLeftVideoWhiteData;
}

export default function ContentSplitLeftVideoWhite({
  data,
}: ContentSplitLeftVideoWhiteProps) {
  return (
    <section
      id="content-split-left-video-white"
      className="py-12 md:py-[50px] px-4"
    >
      <h2 className="text-4xl md:text-6xl font-freightBig text-[#655e5d] mb-8 text-center">
        {data?.heading?.title || ""}
      </h2>
      <div className="max-w-[1400px] mx-auto">
        <div className="grid md:grid-cols-2 gap-8">
          <div className="aspect-video">
              {data?.video?.description && (
                <p className="text-sm text-gray-500 mb-2" dangerouslySetInnerHTML={sanitizeHTML(data.video.description)} />
              )}
              <iframe
                loading={"lazy"}
                src={data?.video?.embed_url || ""}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                className="w-full h-full"
                title={data?.video?.embed_title || ""}
                allowFullScreen
              />
          </div>
          <div className="space-y-2">
            <div 
              className="prose max-w-none leading-normal"
              dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")}
            />
          </div>
        </div>
        <div className="flex justify-center mt-8">
          {data?.button?.href &&  <Button asChild>
            <Link href={data?.button?.href || ""}>{data?.button?.label || ""}</Link>
          </Button>}
        </div>
      </div>
    </section>
  );
}
