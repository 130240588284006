import Image from "next/image";
import { cn } from "@/lib/utils";

interface ContentSplitTextOnlyProps {
  data?: any;
}

const sections = [
  {
    image: "/images/content_split_text_only_black_img.png",
    text: "When it comes to skin rejuvenation, Dr Julian De Silva and his team will generally complete 3 complementary procedures at the same time. The first is CO2 laser and this will stimulate collagen in the superficial layer of the skin and this tends to improve fine lines, enlarged pores and sun damage with pigmentation.",
  },
  {
    text: "The third aspect is we would regenerative medicine, PRP at the same time. In the US has known as the vampire facelift and will go down the pores opened up by the radio frequency and it will rejuvenate your skin further. Dr De Silva has a powerful machine to make high quality PRP that is essentially your own growth factors, that can be used to heal the body faster, ie shorter recovery time. Recovery time: Most patients 2-weeks",
    image: "/images/content_split_text_only_black_img.png",
  },
  {
    image: "/images/content_split_text_only_black_img.png",
    image2: "/images/content_split_text_only_black_img.png",
  },
  {
    image: "/images/content_split_text_only_black_img.png",
    image2: "/images/content_split_text_only_black_img.png",
  },
];

export default function ContentSplitTextOnly({
  data,
}: ContentSplitTextOnlyProps) {
  return (
    <section id="content-split-text-only" className="bg-white py-16 md:py-24">
      <div className="w-[90%] max-w-[1410px] mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
        <div className="space-y-4 pr-4">
          <h4 className="font-aileron font-bold uppercase text-sm tracking-[2px] text-black">
            FACIAL SURGERY IN HARLEY STREET
          </h4>
          <h2 className="font-display text-3xl md:text-[50px] text-[#332f2c] leading-[1.1] mb-6">
            Laser Resurfacing, Radio-frequency & Faster Healing
          </h2>
          <div className="pt-6 max-w-[600px]">
            <Image
              src="/images/content_split_text_only_black_img.png"
              alt="Dr Julian De Silva"
              width={900}
              height={749}
              className="rounded-[18px]"
              quality={95}
              loading={"lazy"}
            />
          </div>
        </div>
        <div className="pl-4">
          <div className="mt-[58px] font-aileron text-base leading-[1.5] text-black">
            <p className="font-bold mb-4">
              Dr. De Silva offers the latest laser technology for rejuvenation
              of your skin in combination with regenerative medicine (Growth
              factor technology), this enables a higher level of laser to be
              used at the same time, reducing the recovery time. Traditionally
              advanced laser treatments resulted in almost 6 months recovery,
              using our latest technology this can be reduced to 2 weeks.
            </p>
            <p className="mb-4">
              Laser treatment improves: wrinkles, sun spots, discolouration, and
              skin tightening. Fractionated CO2 technology differs from the
              previous technology as it is a single treatment that delivers more
              dramatic results than older non-ablative lasers. The technology
              offers a significant improvement over traditional carbon dioxide
              (CO2) lasers because there is no prolonged recovery or redness.
              The laser treatment must be tailored to the individual depending
              on their skin types and the degree of facial ageing. Dr. De Silva
              often uses fractionated CO2 laser resurfacing in conjunction with
              other facial cosmetic surgery to improve the results. The laser
              improves the appearance of the skin by reducing lines, wrinkles,
              sun damage, brown spots, acne scars and improved tone, texture and
              skin tightening.
            </p>
            <p className="mb-4">
              Dr De Silva frequently uses laser with other surgical treatments
              to improve the result. Facial ageing procedures including
              facelift, neck lift and blepharoplasty are often improved further
              when combined with skin resurfacing. This often needs to be
              tailored to the individual patient to match their sun damage, skin
              complexion and ethnicity.
            </p>
            <p className="mb-4">-with Blepharoplasty/ Eyelid surgery</p>
            <p className="mb-4">-with Facelift & Neck lift procedures</p>
            <p className="mb-4">-Skin rejuvenation on its own</p>
            <p>
              Dr. Julian De Silva invests in the latest in technology to offer
              his patients the most effective and fastest possible recovery. Dr.
              De Silva has imported the latest state-of-the-art CO2 Fractional
              Laser from the USA. The fractional CO2 laser penetrates deep into
              your skin and provides heat to stimulate collagen production, and
              the superficial component of the laser is effective for more
              superficial rejuvenation and soften the fine lines and remove
              pigmentation from the top layers of skin. The Cortex laser's
              unique design treats the skin with precise control, and can be
              tailored to a specific problem and allotted downtime. For fine
              lines and superficial sun damage, a single procedure can produce
              significant results with a faster recovery.
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-[1080px] mx-auto px-4">
        <div className="grid grid-cols-1 gap-12">
          {sections.map((section, index) =>
            section.image2 ? (
              <div key={index} className="grid grid-cols-2 gap-12">
                <div className="aspect-w-16 aspect-h-9 relative overflow-hidden">
                  <Image
                    src={section.image}
                    alt="Gallery image"
                    fill
                    className="object-cover"
                    quality={95}
                    loading={"lazy"}
                  />
                </div>
                <div className="aspect-w-16 aspect-h-9 relative overflow-hidden">
                  <Image
                    src={section.image2}
                    alt="Gallery image"
                    fill
                    className="object-cover"
                    quality={95}
                    loading={"lazy"}
                  />
                </div>
              </div>
            ) : (
              <div
                key={index}
                className="grid grid-cols-1 md:grid-cols-2 gap-12 items-start"
              >
                <div
                  className={index % 2 === 0 ? "order-2 md:order-2" : "order-1"}
                >
                  <p className="text-sm font-aileron text-gray-600 leading-[1.8]">
                    {section.text}
                  </p>
                </div>
                <div
                  className={cn(
                    "aspect-w-16 aspect-h-9 relative overflow-hidden",
                    index % 2 === 0
                      ? "order-1 md:order-1"
                      : "order-2 md:order-2"
                  )}
                >
                  <Image
                    src={section.image}
                    alt={section.text?.slice(0, 50) || "Content image"}
                    fill
                    className="object-cover"
                    quality={95}
                    loading={"lazy"}
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
}
