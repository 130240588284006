import Image from "next/image";
import { sanitizeHTML } from "@/lib/utils";
import { getReadableImageUrl } from "@/lib/directus";

interface ContentTextLeftImageBgData {
  heading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  image?: {
    alt?: string;
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
  };
}

export const fields = [
  "heading.text",
  "heading.title",
  "text.text",
  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download",
] as const;

interface ContentTextLeftImageBgProps {
  data?: ContentTextLeftImageBgData;
}

export default function ContentTextLeftImageBg({
  data,
}: ContentTextLeftImageBgProps) {
  return (
    <section id="ContentLeftDark" className="py-8 md:py-12 text-white relative">
      <div className="absolute inset-0 bg-black/75 z-0" />

      <div className="absolute inset-0 -z-10">
        <Image
          src={getReadableImageUrl(data?.image?.image_file.image.id || "", data?.image?.image_file.image.filename_download || "")}
          alt={data?.image?.alt || ""}
          fill
          className="object-cover"
          quality={95}
          loading={"lazy"}
        />
      </div>
      <div className="max-w-[1080px] mx-auto px-4 space-y-4 leading-relaxed relative z-10">
        <h2 className="md:text-5xl text-4xl font-freightBig text-center">
          {data?.heading?.title || ""}
        </h2>
        <div className="prose prose-invert max-w-none" dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")} />
      </div>
    </section>
  );
}
