import Image from "next/image";
import { sanitizeHTML } from "@/lib/utils";
import { getReadableImageUrl } from "@/lib/directus";

interface ContentSplitTextOnlyBlackData {
  heading?: {
    title?: string;
  };
  text?: {
    text?: string;
  };
  images?: {
    images_id: {
      alt: string;
      image_file: {
        image: {
          id: string;
          filename_download: string;
        };
      };
    };
  }[];
  subheading?: {
    title?: string;
  };
}

export const fields = [
  "heading.title",
  "text.text",
  "images.images_id.alt",
  "images.images_id.image_file.image.id",
  "images.images_id.image_file.image.filename_download",
  "subheading.title",
] as const;

interface ContentSplitTextOnlyBlackProps {
  data?: ContentSplitTextOnlyBlackData;
}

export default function ContentSplitTextOnlyBlack({
  data,
}: ContentSplitTextOnlyBlackProps) {
  return (
    <section
      id="content-split-text-only-black"
      className="bg-black py-14 md:py-[54px]"
    >
      <div className="max-w-[1080px] mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="font-display text-white text-2xl md:text-3xl leading-tight mb-4">
              {data?.heading?.title || ""}
            </h3>
            <div 
              className="text-white font-aileron text-base leading-normal prose prose-white prose-ul:pl-5 prose-li:pl-0 max-w-none"
              dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")}
            />
          </div>
          <div className="space-y-4">
            {data?.images?.[0] && (
              <div className="rounded-lg overflow-hidden">
                <Image
                  src={getReadableImageUrl(data.images[0].images_id.image_file.image.id, data.images[0].images_id.image_file.image.filename_download)}
                  alt={data.images[0].images_id.alt}
                  width={800}
                  height={600}
                  className="w-full h-auto"
                  quality={95}
                  loading={"lazy"}
                />
              </div>
            )}
            <h3 className="font-display text-white text-lg md:text-xl leading-tight">
              {data?.subheading?.title || ""}
            </h3>
            {data?.images?.[1] && (
              <div className="rounded-lg overflow-hidden">
                <Image
                  src={getReadableImageUrl(data.images[1].images_id.image_file.image.id, data.images[1].images_id.image_file.image.filename_download)}
                  alt={data.images[1].images_id.alt}
                  width={800}
                  height={600}
                  className="w-full h-auto"
                  quality={95}
                  loading={"lazy"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
