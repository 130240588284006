import Image from "next/image";

interface ContentSplitTextImageProps {
  data?: any;
}

export default function ContentSplitTextImage({
  data,
}: ContentSplitTextImageProps) {
  return (
    <section
      id="ContentSplitTextImage"
      className="bg-black text-white py-10 md:py-16"
    >
      <div className="max-w-[1080px] mx-auto px-4 md:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div>
            <h3 className="text-2xl md:text-3xl font-freightBig mb-4">
              Hidden Scars
            </h3>
            <p className="font-aileron">
              Dr. De Silva hides the scars from facelift and neck lift surgery
              around the natural curves of the neck and in the hairline. These
              photos show the scars at 1-week after surgery, using the natural
              curves of the ears takes additional time however makes scars
              difficult to see. Some surgeons place the scars in front of the
              ear or in front of the sideburn, this can result in visible scars
              which can be very difficult to treat. Dr. De Silva believes that
              scars should be made as discreet as possible. Less than 1% of the
              population may have troublesome scarring such as keloid scars, by
              placing the scars in discrete areas the scars generally are
              difficult to see even soon after facelift and neck lift surgery.
            </p>
          </div>
          <div>
            <Image
              src="/images/content_split_left_image_white_img_3.jpg"
              alt="test"
              width={500}
              height={400}
              quality={95}
              loading={"lazy"}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
