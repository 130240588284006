"use client";

import Image from "next/image";
import Link from "@/components/ui/Link";
import Button from "../ui/button";

interface ContentSplitLeftImageWhiteProps {
  data?: any;
}

export default function ContentSplitLeftImageWhite({
  data,
}: ContentSplitLeftImageWhiteProps) {
  return (
    <section
      id="content-split-left-image-white"
      className="w-full py-[4.5rem] md:py-[5.25rem] bg-white"
    >
      <div className="container">
        <div className="flex flex-col lg:flex-row items-start gap-8 lg:gap-[3.75rem]">
          <div className="w-full lg:w-1/2 lg:pr-[0.9375rem]">
            <h4 className="font-aileron font-bold text-[0.8125rem] tracking-[0.125rem] uppercase text-black mb-[1.125rem]">
              FACELIFT SURGERY IN HARLEY STREET
            </h4>
            <h2 className="font-display text-[1.875rem] md:text-[3.125rem] leading-[1.1] text-[#332f2c] mb-0">
              Blepharoplasty can this procedure help You?
            </h2>
            <div className="mt-6 relative rounded-[1.125rem] overflow-hidden">
              <Image
                src="/images/content_split_left_image_white_img.jpeg"
                alt="Blepharoplasty Before and After"
                width={600}
                height={450}
                className="w-full h-auto"
                quality={95}
                loading={"lazy"}
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2 lg:pl-[0.9375rem] mt-[3.625rem] lg:mt-[3.625rem]">
            <div className="text-black text-base leading-relaxed font-aileron">
              <ul className="list-disc pl-4">
                <li>
                  <strong>Who can benefit from blepharoplasty?</strong> Tired
                  appearance, loose eyelid skin, puffy eyes, skin laxity, bags
                  under eyes, dark circles, asymmetry tired eyes appearance,
                  smudging concealer, being mistake for being tired, lack of
                  sleep… are all common symptoms that Dr Julian De Silva's
                  patients describe.
                </li>
                <li>
                  Dr Julian De Silva developed customised blepharoplasty
                  techniques based on conservative reshaping and repositioning
                  that restores anatomy to where it used to be, this results in
                  a <strong>Natural Blepharoplasty</strong>. Avoid an 'operated
                  appearance' or 'hollowed-out' appearance to the eyelid that is
                  unappealing. Key to excellent surgery is a less is more
                  approach.
                </li>
                <li>
                  <strong>Intricate anatomy of the Eyelids</strong> ensures
                  blepharoplasty is a detailed procedure of millimetre precision
                  and artistry: Important to assess position of brows, asymmetry
                  of eyelid, hollowing of eyelids, skin quality, appearance of
                  swelling (malar bags), previous filler use, tear quality and
                  dry eyes to mention a few. The scars should be hidden and with
                  good surgery no one will be able to tell you have undergone a
                  procedure. Dr Julian De Silva specialises in{" "}
                  <strong>Natural Blepharoplasty</strong>.{" "}
                  <em>
                    "People or celebrities who look like they have had a lot of
                    work done, often their eyes have been changed by
                    blepharoplasty, less is more to keep a natural result."
                  </em>{" "}
                  Dr Julian De Silva.
                </li>
                <li>
                  <strong>Young People</strong> may have Eye Bags as a
                  consequence of genetics, often other family members can also
                  have eyelid changes. Of key importance is anatomy, often there
                  is 'negative vector' which results in eye bags at young age,
                  Dr De Silva previously wrote a book chapter on this subject
                  and avoid complications such as eyelid retraction. In these
                  patients the incision should be 'trans-conjunctival' (inside
                  of the eyelid) with fat transfer to preserve the natural shape
                  of your eyelid and mid-face.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row mt-8 gap-8 max-w-[1080px] mx-auto px-4 text-sm">
          <div className="w-full lg:w-3/5">
            <div className="space-y-6">
              <Image
                src="/images/content_split_left_image_white_img_2.webp"
                alt="Dr De Silva's Blepharoplasty Patient Guide book"
                width={2160}
                height={2880}
                className="w-full h-auto"
                quality={95}
                loading={"lazy"}
              />
              <Image
                src="/images/content_split_left_image_white_img_3.jpg"
                alt="Delicate Eyelid Anatomy from Dr Julian DeSilva's Blepharoplasty Book"
                width={1563}
                height={1137}
                className="w-full h-auto"
                quality={95}
                loading={"lazy"}
            />
            </div>
          </div>
          <div className="w-full lg:w-2/5">
            <div className="text-black text-sm leading-relaxed font-aileron">
              <ul className="list-disc pl-4 space-y-4">
                <li>
                  <Link href="/about" className="text-black hover:underline">
                    Avoid Complications with a board certified{" "}
                    <strong>Oculo-Plastic Surgeon</strong> specialising in
                    eyelids, Dr Julian De Silva. Not a general plastic surgeon
                    (completing breast & body surgery) or ENT surgeon
                    (specialising in nasal surgery) or Maxillary-Facial surgeon
                    specialising in teeth).
                  </Link>{" "}
                  Dr De Silva wrote an extensive book that includes everything
                  patient need to know regarding blepharoplasty which is gifted
                  to all our patients undergoing treatment.
                </li>
                <li>
                  <strong>20-30% Revision Blepharoplasty</strong>, of the
                  patients Dr De Silva treats have had previous eyelid surgery
                  elsewhere and were unsatisfied with the results from their
                  previous surgeon. There can be many reasons for this, not
                  adequate improvement, lose skin and puffiness remains, short
                  longevity, visible scars.
                </li>
                <li>
                  <strong>World Travelled Eyelid Specialist</strong>, Dr Julian
                  De Silva spent time working in the very best Specialist Eye
                  Hospitals in the World before setting up his practice over
                  10years ago, this included <em>Moorfields Eye Hospital</em> in
                  London, <em>UCLA Jules Stein Eye Institute</em> in Los Angeles
                  and{" "}
                  <em>
                    Montefiore Medical Center, Albert Einstein College of
                    Medicine
                  </em>{" "}
                  in New York.
                </li>
                <li>
                  Dr De Silva specializes in <strong>Face Surgery Only</strong>{" "}
                  and <strong>Blepharoplasty is the #1 Procedure</strong> he
                  completes for his patients.
                </li>
                <li>
                  Dr De Silva uses specialised{" "}
                  <strong>state-of-the-art technology</strong> to improve the
                  natural results or enhance recovery or longevity of the
                  procedure. These include a US-manufactured CO2 laser or
                  monopolar electrocautery, to establish meticulous control of
                  bleeding which is key to preventing both bruising and
                  preserving good vision after surgery.
                </li>
                <li>
                  <strong>Virtually Invisible Scars</strong>. Key to World Class
                  Blepharoplasty surgery is virtually invisible scars. There are
                  many important factors to consider including, natural skin
                  position, asymmetry, ethnicity, skin laxity, skin quality and
                  other diagnoses (hidden ptosis, puffiness). The skin incision
                  is closed using a combination of interrupted and continuous
                  non-dissolving fine stitches. Non-dissolving stitches are
                  preferred as they avoid scarring and stitch lines. Dissolvable
                  sutures although saves time for surgeons as they dont need to
                  be removed, these can create additional scarring.
                </li>
                <li>
                  <Link
                    href="/lower-blepharoplasty"
                    className="underline hover:text-primary font-bold"
                  >
                    For information on LOWER EYELID BLEPHAROPLASTY -&gt;
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <Button asChild>
            <Link href="/book-a-consultation">Book a consultation</Link>
          </Button>
        </div>
      </div>
    </section>
  );
}
